import React, {  useState, useEffect }from "react";
import { useTheme } from "../ThemeContext";
import "../App.css";
import { Link } from "react-router-dom";
import ToggleSwitch from "../ToggleSwitch";
import axios from "axios"
import { API_BASE_URL } from "../App";


const Nav = () => {
    const [menuOpen, setMenuOpen] =useState(false);
    const { colors, isDarkMode} = useTheme();
    const [logo, setLogo] = useState(null);

    useEffect(() => {
        const fetchLogoData = async () => {
            try {
                const logoResponse = await axios.get(
                    `${API_BASE_URL}/sitestructure/api/${
                        isDarkMode ? "dark" : "light" 
                }-logo/`
            );
                setLogo(logoResponse.data[0]);

                
            } catch (error) {
                console.error("Error fetching logo data:", error)
            }
        };
        fetchLogoData();
    }, [isDarkMode]);
    
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const scrollToSection =(sectionId) => {
        const section = document.getElementById(sectionId);
        if(section) {
            section.scrollIntoView({ behavior: "smooth" });
        } else if (sectionId === "home") {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    };

    const navStyle = {
        backgroundColor: colors && (isDarkMode ? colors["bg-color-dark"] : colors["bg-color-light"]),
        color: colors && (isDarkMode ? colors["txt-color-white"] : colors["txt-color-black"]),
    }
    
   
    
    return (
        <nav className="navbar" style={navStyle}>
            <Link to="/#" onClick={() => scrollToSection("home")}>
                 {logo && (
                    <img src={logo.image} alt="" />
                 )}
            </Link>
            
            <div className= {`nav-links ${menuOpen ? "visible" : ""}`}>
                <ul>
                    <li><Link to ="/#" onClick={() => scrollToSection("home")}>Home</Link></li>
                    <li><Link to ="/#" onClick={() => scrollToSection("about")}>About</Link></li>
                    <li><ToggleSwitch /></li>
                </ul>
                
            </div>
    
            <div className={`menu-icon ${menuOpen ? "open" : ""}`} onClick={toggleMenu}>
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
            </div>

            
        </nav>
        
    );
};

export default Nav;
