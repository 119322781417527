import React, { createContext, useContext, useEffect, useState  } from "react";
import axios from "axios"
import { API_BASE_URL } from "./App";


const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const storedTheme = window.localStorage.getItem("theme");
    const [isDarkMode, setIsDarkMode] = useState(storedTheme === "dark");
    const [colors, setColors] = useState(null);
    

    useEffect(() => {
        const storedTheme = window.localStorage.getItem("theme")
        setIsDarkMode(storedTheme === "dark");
    }, []);

    useEffect(() => {
        const fetchThemeData = async () => {
            try {
                const colorsResponse = await axios.get(`${API_BASE_URL}/sitestructure/api/${isDarkMode ? 'dark' : 'light'}-color/`);
                const colorsData = colorsResponse.data;
                const colorsObject = {};

                colorsData.forEach(color => {
                    colorsObject[color.name] = color.hex_code;
                });

                setColors(colorsObject);

        

            } catch (error) {
                console.error("Error fetching theme data:", error)
            }
        };
        fetchThemeData();
    }, [isDarkMode]);

    const toggleTheme = () => {
        const newTheme = isDarkMode ? "light" : "dark";
        setIsDarkMode(newTheme === "dark");
        window.localStorage.setItem("theme", newTheme);
    };

    const setTheme =(theme) => {
        setIsDarkMode(theme === 'dark');
    };

    const theme = {
        isDarkMode,
        toggleTheme,
        setTheme,
        
    };

    return (
        <ThemeContext.Provider value={{...theme, colors}} >
            {children}
        </ThemeContext.Provider>
    );
};


export const useTheme = () => {
    return useContext(ThemeContext);
};