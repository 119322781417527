import React, { useState, useEffect } from "react";
import axios from "axios";
import "../App.css";
import { useTheme } from "../ThemeContext";
import { API_BASE_URL } from "../App";

const Header = () => {
    const[headerData, setHeaderData] = useState(null);
    const { colors, isDarkMode } = useTheme();
    
    
    

    useEffect(() => {
        const fetchHeaderData = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/sitestructure/api/language/?field=Header`);
                setHeaderData(response.data[0]);
            } catch(error) {
                console.error("Error fetching data:", error)
            }
        
        };
        fetchHeaderData();
    }, []);
    


    const headerStyle = {
        backgroundColor: colors && (isDarkMode ? colors["bg-color-dark"] : colors["bg-color-light"]),
        color: colors && (isDarkMode ?  colors["header-txt-dark"] : colors["header-txt-light"]),
    };

    return (
            <div id="home" className="header" style={headerStyle}>
                {headerData && (
                    <h1>{headerData.text}</h1>
                )}
            </div>


    );
};

export default Header;
