import React from "react";
import { HelmetProvider } from "react-helmet-async";
import './App.css';
import Metadata from "./metadata";
import Nav from "./components/Nav";
import Header from "./components/Header";
import About from "./components/About";
import Footer from "./components/Footer";
import { ThemeProvider } from "./ThemeContext";

export const API_BASE_URL = process.env.NODE_ENV ==='production'
? `${window.location.protocol}//${window.location.host}`
: 'http://127.0.0.1:8000';


function App() {
  const siteName = document.getElementById('root').getAttribute('data-site-name');

  return (
    <HelmetProvider>
      <ThemeProvider>
        <div className="container">
          <Metadata siteName={siteName}/>
          <Nav />
          <Header />
          <About />
          <Footer />
        </div>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
