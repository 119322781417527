import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "./App";
import { Helmet, HelmetProvider } from "react-helmet-async";


const Metadata = () => {
    const [metadata, setMetadata] = useState({
        title: 'Default Title',
        description: 'Default Description',
        image: 'Default Image',
    });

    useEffect(() => {
        const fetchMetadata = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/sitestructure/api/company-data/`);
                const data = await response.json();
                setMetadata({
                    title: data[0].name,
                    description: data[0].description,
                    image: data[0].favicon,
                });
            } catch(error) {
                console.error('Error fetching metadata:', error);
            }
        };
        fetchMetadata();
    }, []);

    return (
        <HelmetProvider>
            <Helmet>
                <title>{metadata.title}</title>
                <meta name="description" content={metadata.description} />
                <meta property="og:title" content={metadata.title} />
                <meta property="og:description" content={metadata.description} />
                <meta property="og:image" content={metadata.image} />
            </Helmet>
        </HelmetProvider>

    );
};

export default Metadata;