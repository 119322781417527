import React, { useState, useEffect } from "react";
import axios from "axios";
import '../App.css';
import { useTheme } from "../ThemeContext";
import { API_BASE_URL } from "../App";


const Footer = () => {
    const [footerData, setFooterData] = useState(null);
    const { colors, isDarkMode } = useTheme();

    useEffect(() => {
        const fetchFooterData = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/sitestructure/api/language/?field=Footer`);
                setFooterData(response.data[0]);
            } catch(error) {
                console.error("Error fetching data:", error)
            }
        };
        fetchFooterData();
    }, []);

    const footerStyle = {
        backgroundColor: colors && (isDarkMode ? colors["bg-color-dark"] : colors["bg-color-light"]),
        color: colors && (isDarkMode ? colors["txt-color-white"] : colors["txt-color-black"]),

    };
    return (
        <footer className="footer-container" style = {footerStyle}>
            {footerData && (
                <p>{footerData.text}</p>
            )}
        </footer>
        
    );
};

export default Footer;
