import React  from 'react';
import { Sun, Moon } from 'lucide-react';
import { useTheme } from "./ThemeContext";

function ToggleSwitch() {
  const { isDarkMode, toggleTheme } =  useTheme();

  const handleChange = () => {
    toggleTheme();
  }

  React.useEffect(() => {
    const rootElement =document.documentElement;
    rootElement.setAttribute('data-theme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  return (
    <button className={`theme-toggle ${isDarkMode ? 'dark' : 'light'}`} onClick={handleChange}>
      {isDarkMode ? <Sun size={18} strokeWidth={1.5}/> : <Moon size={18} strokeWidth={1.5}/>}
    </button>
  );
};

export default ToggleSwitch;