import React, { useState, useEffect } from "react";
import axios from "axios";
import "../App.css";
import { useTheme } from "../ThemeContext";
import { API_BASE_URL } from "../App";


const About = () => {
    const [aboutData, setAboutData] =useState(null);
    const { colors, isDarkMode } = useTheme();


    useEffect(() => {
        const fetchAboutData = async () => {
            try {
                const aboutResponse = await axios.get(`${API_BASE_URL}/sitestructure/api/language/`)
                const filteredData = aboutResponse.data.filter(item => {
                    return item.field === 'About_title' || item.field === 'About_description';
                })
                setAboutData(filteredData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchAboutData();
    }, []);

    
    const aboutStyle = {
        backgroundColor: colors && (isDarkMode ? colors["about-color-dark"] : colors["about-color-light"]),
        color: colors && colors["txt-color-white"]
    }

    return (
            <div id="about" className="about-container" style={aboutStyle}>

                <div className="about-content">
                    {aboutData && (
                        <h2>{findFieldText(aboutData, 'About_title')}</h2>
                        
                    )}
                    {aboutData && (
                        <p>{findFieldText(aboutData, 'About_description')}</p>
                    )}
                </div>  
            </div>
        
    
       
    );
};

const findFieldText = (data, fieldName) => {
    const fieldData = data.find(item => item.field === fieldName);
    return fieldData ? fieldData.text : '';
};

export default About;


